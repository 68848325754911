import React, { useState } from 'react'
import {
    Tabs,
    Card,
    Pagination,
    ResourceList,
    ResourceItem,
    TextStyle,
    Avatar
} from '@shopify/polaris';
import httpClient from '../http';

const API_URL = process.env.REACT_APP_API_URL || "https://api.shoplft.co/";
const client = new httpClient(API_URL);

export default class TabsExample extends React.Component {
    state = {
        selected: 0,
        currentPage: 0,
        currentItems: [],
    }

    async componentDidMount() {
        let [items, pageToken] = await this.fetchProducts(this.state.selected, 0)
        this.items[0][0] = items;
        this.pageTokens[this.state.selected] = pageToken;
        this.setState({ currentItems: items })
    }


    tabs = [
        {
            id: 'products',
            content: 'Your Products',
            accessibilityLabel: 'Your Products',
            panelID: 'all-customers-content',
        },
        {
            id: 'product-viewings',
            content: 'Your Products Visible On Shoplft',
            panelID: 'viewing-product-content',
        },
        {
            id: 'product-listings',
            content: 'Your Products Sold On Shoplft',
            panelID: 'accepts-marketing-content',
        },
    ];

    items = [
        [],
        [],
        []
    ];

    invalidateData = (tabToInvalidate) => {
        this.setState(this.items[tabToInvalidate], []);
        this.setState(this.pageTokens[tabToInvalidate], '');
        this.setState(this.maxPage, -1);
    }


    promotedBulkActions = [
        [
            {
                content: 'Add to Sold on Shoplft ',
                onAction: async () => { },
            },
        ],
        [],
        []
    ];


    pageTokens = [
        '',
        '',
        '',
    ]

    fetchProducts = async (tab, page) => {
        if (this.items[tab][page]) {
            return [this.items[tab][page], this.pageTokens[tab]]
        }

        switch (tab) {
            case 0:
                return GetProducts(this.pageTokens[tab])
            case 1:
                return GetListedProducts(this.pageTokens[tab])
            case 2:
                return GetProductsSoldOnShoplft(this.pageTokens[tab])
            default:
                return Promise.reject()

        }
    }

    cacheItems = (tab, page, items) => {
        this.items[tab][page] = items
    }

    handleTabChange = (newTab) => {
        this.setState({ selected: newTab, currentPage: 0, currentItems: [] }, async () => {
            let [items, pageToken] = await this.fetchProducts(this.state.selected, this.state.currentPage);
            this.setState({ currentItems: items }, () => {
                this.pageTokens[newTab] = pageToken;
                this.cacheItems(newTab, 0, items);
            });
        })
    }

    nextPage = async () => {
        this.setState({ currentPage: this.state.currentPage + 1 }, async () => {
            let [items, pageTok] = await this.fetchProducts(this.state.selected, this.state.currentPage)
            this.pageTokens[this.state.selected] = pageTok
            if (pageTok === "") {
                this.maxPage[this.state.selected] = this.state.currentPage;
            }

            this.setState({ currentItems: items });
            this.cacheItems(this.state.selected, this.state.currentPage, items);
        });

    }

    previousPage = async () => {
        this.setState({ currentPage: this.state.currentPage - 1 }, async () => {
            let [items] = await this.fetchProducts(this.state.selected, this.state.currentPage)
            this.setState({ currentItems: items });
            this.cacheItems(this.state.selected, this.state.currentPage, items);
        });
    }

    maxPage = [
        -1,
        -1,
        -1
    ]

    render() {
        return (
            <Card>
                <Tabs tabs={this.tabs} selected={this.state.selected} onSelect={this.handleTabChange}>
                    <Card.Section title={this.tabs[this.state.selected].content}>
                        {<ResourceListWithBulkActions
                            items={this.state.currentItems}
                            promotedBulkActions={this.promotedBulkActions[this.selected]}
                            // bulkActions={this.bulkActions[this.state.selected]}
                            selected={this.state.selected}
                        />}
                        <Pagination
                            label="Products"
                            hasPrevious={this.state.currentPage > 0}
                            onPrevious={this.previousPage}
                            hasNext={this.maxPage[this.state.selected] === -1 || this.state.currentPage < this.maxPage[this.state.selected]}
                            onNext={this.nextPage}
                        />
                    </Card.Section>
                </Tabs>
            </Card >)
    }
}



function ResourceListWithBulkActions({ items, promotedBulkActions, invalidateData, selected }) {
    const [selectedItems, setSelectedItems] = useState([]);

    const check = (a, b, c) => {
        if (typeof (a[0]) === "string" && a.length === 25) {
            setSelectedItems(items.map(i => {
                console.log(i)
                return i.original_id
            }));
        } else {
            setSelectedItems(a);
        }
        // if (a.length == 25) {

        // }
        // console.log(a, b, c);
    }

    const resourceName = {
        singular: 'product',
        plural: 'products',
    };

    const bulkActions = [
        [
            {
                content: 'Add to Visible on Shoplft ',
                onAction: async () => {
                    try {
                        await AddToShoplft("viewable", selectedItems);
                        //invalidateData(1);
                        // do something to invalidate the data
                    } catch {
                        console.log("TODO: implement failed request handling")
                    }
                },
            },
            {
                content: 'Add to Sold on Shoplft ',
                onAction: async () => {
                    try {
                        await AddToShoplft("selling", selectedItems);
                        // invalidateData(2);
                        // do something to invalidate the data
                    } catch {
                        console.log("TODO: implement failed request handling");
                    }
                },
            },
        ],
        [
            {
                content: 'Remove from Shoplft',
                onAction: async () => {
                    try {
                        await RemoveFromShoplft("viewable", selectedItems);
                        // invalidateData -> same page as the one you're on
                        // do something to invalidate the data
                    } catch {
                        console.log("TODO: implement failed request handling")
                    }
                }
            },
        ],
        [
            {
                content: 'Remove from selling on Shoplft',
                onAction: async () => {
                    try {
                        await RemoveFromShoplft("selling", selectedItems);
                        // invalidateData -> same page as the one you're on
                        // do something to invalidate the data
                    } catch {
                        console.log("TODO: implement failed request handling")
                    }
                }
            },
        ]

    ];

    return (
        <Card>
            <ResourceList
                resourceName={resourceName}
                items={items}
                renderItem={renderItem}
                selectedItems={selectedItems}
                onSelectionChange={check}
                promotedBulkActions={promotedBulkActions}
                bulkActions={bulkActions[selected]}
            />
        </Card>
    );

    function renderItem(item) {
        const { original_id, title, location } = item;
        let id = original_id;
        let name = title;
        const media = <Avatar customer size="medium" name={name} />;

        return (
            <ResourceItem
                id={id}
                //url={url}
                media={media}
                accessibilityLabel={`View details for ${name}`}
            >
                <h3>
                    <TextStyle variation="strong">{name}</TextStyle>
                    {/*<p>{id}</p>*/}
                </h3>
                <div>{location}</div>
            </ResourceItem>
        );
    }
}



//these functions should return null when
async function GetProducts(pageToken) {
    let { data, next } = await client.get("/shop/items", {
        page_token: pageToken,
    });

    return Promise.resolve([data, next])
}

async function GetListedProducts(pageToken) {
    let { data, next } = await client.get("/shop/items", {
        type: "viewable",
        pageToken: pageToken,
    });

    return Promise.resolve([data, next])
}

async function GetProductsSoldOnShoplft(pageToken) {
    let { data, next } = await client.get("/shop/items", {
        type: "selling",
        pageToken: pageToken,
    });

    return Promise.resolve([data, next])
}

async function AddToShoplft(add_type, ids, all = false) {
    if (ids.length === 0 && !all) {
        return Promise.reject();
    }

    var requestBody = {
        add_type: add_type,
        ids: ids,
        all: all
    }

    if (all) { requestBody.ids = [] }

    return client.post('https://localhost:8081/shop/items', requestBody)
}


async function RemoveFromShoplft(product_type, ids) {
    if ((typeof (ids) == "object" && ids.length === 0) || ids === undefined) {
        return Promise.reject("no product ids provided");
    }

    console.log(client.resolveURL("/shop/items"));

    return client.delete('/shop/items', {
        id: ids.join(), //converts array to comma delimited string
        type: product_type,
    })
}