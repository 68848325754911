import React, { useState, useCallback } from 'react';
import {
  Layout,
  Page,
  Link,
  AccountConnection,
} from '@shopify/polaris';
import TabsExample from './components/TabExample'


export default function App() {
  const [connected, setConnected] = useState(false);

  const accountSectionDescription = connected
    ? 'Disconnect your account from your Shopify store.'
    : 'Connect your account to your Shopify store.';
  const toggleConnection = useCallback(() => { setConnected(!connected) }, [connected])

  const accountMarkup = connected ? (
    <DisconnectAccount onAction={toggleConnection} />
  ) : (
      <ConnectAccount onAction={toggleConnection} />
    );



  return (<Page
    title="Shoplft Wishlist"
  >
    <Layout>
      <Layout.AnnotatedSection
        title="Account"
        description={accountSectionDescription}
      >
        {accountMarkup}
      </Layout.AnnotatedSection>
      <Layout.AnnotatedSection
        title="Products"
        description="Control which products can be bought and seen on Shoplft"
      >
        <TabsExample></TabsExample>
      </Layout.AnnotatedSection>
    </Layout>
  </Page >)
}


function ConnectAccount({ onAction }) {
  return (
    <AccountConnection
      action={{ content: 'Connect', onAction }}
      details="No account connected"
      termsOfService={
        <p>
          By clicking Connect, you are accepting Sample’s{' '}
          <Link url="https://polaris.shopify.com">Terms and Conditions</Link>,
          including a commission rate of 15% on sales.
        </p>
      }
    />
  );
}

function DisconnectAccount({ onAction }) {
  return (
    <AccountConnection
      connected
      action={{ content: 'Disconnect', onAction }}
      accountName="Tom Ford"
      title={<Link url="http://google.com">Tom Ford</Link>}
      details="Account id: d587647ae4"
    />
  );
}