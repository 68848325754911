// httpClient provides a promise based api for making http requests
// it returns an object with a data field containing the response body
// and an optional parameter next which is used for page based requests

class httpClient {
    constructor(url) {
        if (url !== undefined) {
            if (url[url.length - 1] === "/") {
                this.URL = url.substring(0, url.length - 1)
            } else {
                this.URL = url
            }
        }
    }

    getURL() {
        return this.URL
    }

    resolveURL(url) {
        if (url[0] === "/") {
            //relative url
            return this.URL + url
        } else {
            return url
        }
    }

    async get(url = '', query = {}) {
        const resolvedURL = this.resolveURL(url);
        const queryURL = this.parseQueryParams(query);
        const response = await fetch(resolvedURL + queryURL, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer"
        })

        if (response.ok) {
            const json = await response.json();
            const nextPage = response.headers.get('X-Shoplft-Next-Page') || '';
            return Promise.resolve({
                data: json,
                next: nextPage,
            })
        } else {
            return Promise.reject()
        }
    }
    async post(url = '', body = {}, query = {}) {
        const resolvedURL = this.resolveURL(url);
        const queryURL = this.parseQueryParams(query);
        const response = await fetch(resolvedURL + queryURL, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(body)
        })

        if (response.ok) {
            const json = await response.json();
            const nextPage = response.headers.get('X-Shoplft-Next-Page') || '';
            return Promise.resolve({
                data: json,
                next: nextPage,
            })
        } else {
            return Promise.reject()
        }
    }
    async put(url = '', body = {}, query = {}) {
        const resolvedURL = this.resolveURL(url);
        const queryURL = this.parseQueryParams(query);
        const response = await fetch(resolvedURL + queryURL, {
            method: "PUT",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(body)
        })

        if (response.ok) {
            const json = await response.json();
            const nextPage = response.headers.get('X-Shoplft-Next-Page') || '';
            return Promise.resolve({
                data: json,
                next: nextPage,
            })
        } else {
            return Promise.reject()
        }
    }
    async delete(url, query = {}) {
        const resolvedURL = this.resolveURL(url);
        const queryURL = this.parseQueryParams(query);
        const response = await fetch(resolvedURL + queryURL, {
            method: "DELETE",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer"
        })

        if (response.ok) {
            const json = await response.json();
            const nextPage = response.headers.get('X-Shoplft-Next-Page') || '';
            return Promise.resolve({
                data: json,
                next: nextPage,
            })
        } else {
            return Promise.reject()
        }
    }

    parseQueryParams(query = {}) {
        let params = '';
        if (query) {
            Object.keys(query).forEach((e) => {
                if (query[e]) {
                    if (params === '') {
                        params = '?'
                    } else {
                        params += '&'
                    }

                    params += encodeURI(e) + '=' + encodeURI(query[e])
                }
            })
        }
        console.log(params);
        return params
    }
}

export default httpClient 